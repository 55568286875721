<template>
<div class="bgm" style="overflow-y:scroll;overflow-x:hidden;height:100%">
    <div class="over_bgc">
        <!-- 顶部栏 -->
        <top-banner theme="white" @backHome="backHome"></top-banner>
        <!-- 详情 -->
        <div class="main x_between">
            <!-- 左边 -->
            <div class="left" style="flex:1">
                <img src="@/assets/image/home/sixPage/BIMOM.jpg" class="poster">
                <div class="column">
                    <div style="color:#fff;margin-top:30px">{{title}}</div>
                    <div style="color:#797979">日期：{{(date)}}  作者：{{author}}</div>
                    <div class="back" @click="backPage">返回</div>
                </div>
            </div>
            <!-- 右边 -->
            <div class="right" style="flex:2">
                <div class="content" >{{content}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
export default {
    name: 'twoPage',
    data(){
        return {
            title:"BIMOM 产品常见问题 FAQ",
            date:"2022-04-23",
            author:"成都悟空和展昭科技有限公司",
            contents:[
                {
                    type:'',
                    question:'',
                    answer:'',
                }
            ],
            content:"一、安装注册问题\n" +
                "1、Q：安装完相关插件后，Archiad面板无插件菜单？\n" +
                "   A:插件安装包默认安装在C盘的Archiad根目录，如果用户安装Archicad时更改了安装路径，则需要在安装插件时手动选择到对应的Archicad根目录（注意，是根目录，不是Add-Ons目录）。\n" +
                "2、Q：插件注册时提示手机号已注册？\n" +
                "   A：请检查之前是否使用该手机号注册BIMOMid相关产品，一个手机号只能注册一次。\n" +
                "3、Q：插件登录后使用相关功能提示未授权？\n" +
                "   A：BIMOM相关产品提供免费试用期，ACurRebar默认7天，BIMOM相关产品默认15天，到期后需要购买相关授权，请联系工作人员。 \n" +

                "\n",
            url:""
            }
    },
    components:{
        topBanner
    },
    mounted(){
        //this.title= this.$route.query.title;
        this.findFAQs();

    },
    methods:{
        formatDate1(data){
            let dt = new Date(data)
            return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
        },
        backPage(){
            this.$router.go(-1)
        },
        backHome(){
            this.$router.push("/")
        },
        findFAQs(){
            let config = {
                headers : {
                    'Content-Type':'application/json'
                }
            };
            const param={
                title:this.title,
            };
            this.axios.post(this.VUE_APP_BASE_API+'/FAQ/getFAQs',param,config).then((res)=>{
                if(res.data.resultCode==0){
                    this.tabledatas=[];
                    this.contents=[];
                    res.data.resultData.forEach((val)=>{
                        let data={
                            type:val.type,
                            question:val.question,
                            answer:val.answer,
                        }
                        this.contents.push(data);
                    });
                    this.content='';
                    console.log(this.contents);
                    let titles="";
                    let ind=1;
                    let index=1;
                    for (let i = 0; i < this.contents.length; ++i) {
                        if(titles!=this.contents[i].type){
                            this.content=this.content+this.toChineseNum(ind)+ "、："+ this.contents[i].type+"：\n";
                            titles=this.contents[i].type;
                            ind=ind+1;
                            index=1;
                        }
                        this.content=this.content+"   "+(index)+"、Q："+ this.contents[i].question+"\n";
                        this.content=this.content+"      A："+this.contents[i].answer+"\n\n";
                        index=index+1;
                    }
                    console.log(this.content);
                }else{
                    alert(res.data.resultMsg);
                }
            });
        },
        toChineseNum(num){
            let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
            let unit = ["", "十", "百", "千", "万"];
            num = parseInt(num);
            let getWan = (temp) => {
                let strArr = temp.toString().split("").reverse();
                let newNum = "";
                for (var i = 0; i < strArr.length; i++) {
                    newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
                }
                return newNum;
            }
            let overWan = Math.floor(num / 10000);
            let noWan = num % 10000;
            if (noWan.toString().length < 4) noWan = "0" + noWan;
            return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
        }
    }
}
</script>

<style lang="scss" scoped>
.bgm{
  width: 100%;
  height: 100vh;
  background: url("../assets/image/home/sixPage/bgm.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  .over_bgc{
    width: 100%;
    min-height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    padding: 50px 70px !important;
  }
}
.main{
    margin-top: 60px;
    .left{
        font-family: '幼圆 Bold', '幼圆 Regular', '幼圆', sans-serif;
        font-weight: bold;
        font-size: 22px;
        color: #FFFFFF;
        margin-top: 30px;
        position: relative;
        .poster{
            width: 100%;
            border-radius: 10px;
        }
        .column{
            div{
                margin-top: 40px;
            }
            .back{
                position: absolute;
                bottom: 0;
                cursor: pointer;
                color:#797979;
                &:hover{
                    color: #02A7F0;
                }
            }
        }
        
    }
    .right{
        width: 100%;
        height: 750px;
        overflow: auto;
        .content{
            color: #fff;
            font-family: '幼圆', sans-serif;
            padding: 0 40px;
            font-size: 22px;
            line-height: 30px;
            white-space: pre-wrap;
        }
    }
}

</style>
